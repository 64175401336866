#dashboard .ant-table-wrapper .ant-table-thead >tr>th, 
#dashboard .ant-table-wrapper .ant-table-thead >tr>td {
    position: relative;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    text-align: start;
    background: #ffffff;
    border-bottom: 0px solid #f0f0f0;
    transition: background 0.2s ease;
}

#dashboard .ant-table-wrapper .ant-table-tbody >tr >th, 
#dashboard .ant-table-wrapper .ant-table-tbody >tr >td {
    border-bottom: 0px solid #ffffff;
}

#dashboard .ant-table-wrapper .ant-table-tbody >tr:nth-child(odd) >th, 
#dashboard .ant-table-wrapper .ant-table-tbody >tr:nth-child(odd) >td {
    background-color: #f6f7f9;
}

#dashboard .ant-table-wrapper .ant-table-tbody >tr >th:first-child, 
#dashboard .ant-table-wrapper .ant-table-tbody >tr >td:first-child {
    border-radius: 12px 0px 0px 12px;
}

#dashboard .ant-table-wrapper .ant-table-tbody >tr >th:last-child, 
#dashboard .ant-table-wrapper .ant-table-tbody >tr >td:last-child {
    border-radius: 0px 12px 12px 0px;
}

#dashboard .ant-card .ant-card-head-title {
    padding-block: 18px;
}

@media screen and (max-width: 768px) {
  .drawer-dashboard .responsive-drawer {
      width: 100vw !important; /* Adjust the width for smaller screens */
    } 
}

@media screen and (min-width: 768px) {
  .drawer-dashboard .responsive-drawer {
     width: 668px !important; /* Set a fixed width for larger screens */
   } 
}