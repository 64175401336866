@media print {
  @page {
    size: A4;
    font-family: open sans, Helvetica, Arial, sans-serif; 
  }
  #pickup.page-show {
    visibility: visible;
    margin: 0px !important;
    padding: 0px !important;
    display: block;
  }
  .pickup-pages { 
    letter-spacing: .48px;
    width: 21cm; /* A4 width */
    height: 29.7cm; /* A4 height */
    /* margin: 0px !important; */
    /* padding: 0px !important; */
    padding-inline: 0.15in;
    padding-block: 8mm;
    /* margin: 1cm; Margins */
    /* padding: 1cm; */
    /* page-break-after: always;    */
    /* position: absolute; */
    /* left: 0;  */
    /* top: 0;  */
    box-shadow: none !important;
    border-radius: 0px !important;
    font-family: var(--font-family); 
    background-color: #ffffff;
  } 

  
  .pickup-pages .ant-table-wrapper .ant-table-thead >tr>th, 
  .pickup-pages .ant-table-wrapper .ant-table-thead >tr>td { 
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000;
    font-size: 12pt;
  }
   
  .pickup-pages .ant-table-wrapper .ant-table-body tr td.ant-table-cell { 
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000;
    font-size: 12pt;
    height: 32px;
    max-height: 32px;
    min-height: 32px;
  }

  .page-break {
    page-break-before: always; 
  }
}
 
  #pickup.page-show {
    ---width-padding-param : 4mm;
    ---width-param : 0mm;
    ---width-items : calc( 21cm - var(---width-param) );  
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    overflow: auto;
    padding: 1rem;
    background-color: #d9d9d9;
  }

  #pickup .title-preview { 
    width: calc( 21cm + (var(---width-padding-param) * 2) ); /* A4 width */
    box-sizing: border-box;
  }

  #pickup .layout-preview {
    padding: var(---width-padding-param);
    width: calc( 21cm + (var(---width-padding-param) * 2) ); /* A4 width */
    /* min-height: 29.7cm;*/
    height: auto; 
    /* min-height: 29.7cm; */
    box-sizing: border-box;
    box-shadow: 1px 1px 2px 1px #c5c7c5;
    border-radius: 4px;
    background-color: #fff;
    letter-spacing: 0px; 
    color: #000;
    position: relative;
  }

  .pickup-pages {
    position: relative;
    width: 21cm;
    min-height: 29.7cm;
  }

  .pickup-pages .print-logo {
    position: absolute;
    right: 1.5mm;
    top: 1.5mm;
    left: auto;
  }
  
  .pickup-pages .print-title {
    width: 100%;
    /* border-bottom: 2px solid #508ecc; */
  }

  .pickup-pages .print-title p {
    margin-bottom: .234rem;
  }

  .pickup-pages .print-head .print-title { 
    border-bottom: 2px solid #508ecc;
  }

  .pickup-pages .print-foot .print-title { 
    border-top: 2px solid #508ecc;
  }
  
  .pickup-pages .print-head{
    width: 100%;
    position: relative;
    /* height: 26.8mm; */
    padding: 1.4mm;
    border-bottom: 0px solid #000;  
    display: flex;
  }
  
  .pickup-pages .print-head-content{
    width: 100%;
    position: relative;
    /* height: 40mm;  */
    padding-block: 1.4mm;
    padding-inline: 1.4mm;
    border-bottom: 0px solid #000; 
    display: inline-flex;
    flex-direction: column;
  }
  
  .pickup-pages .print-foot{
    width: 100%;
    position: relative;
    /* height: 40mm;  */
    padding-block: 1.4mm;
    padding-inline: 1.4mm; 
    /* bottom: 12px; */
    /* left: 0; */
    display: flex;
  }
  
  .pickup-pages .print-content {
    width: 100%;
    position: relative;
    /* height: 211.34mm;  */
    display: flex;
    flex-direction: column;
    padding-block: 1.4mm;
    padding-inline: 1.4mm;
    border-bottom: 0px solid #000;  
  }
  
  .pickup-pages .th-text{
    font-size: 13pt;
    font-weight: 700; 
  }
  
  .pickup-pages .ts-text{
    font-size: 10pt;
    font-weight: 700; 
  }
  
  .pickup-pages .h-group-text {
    font-weight: 600;
    display: flex; 
    font-size: 10pt;
  }
  
  .pickup-pages span.h-label {
    width: 180px;
  }
  
  .pickup-pages .h-group-text span {
    line-height: 1.5rem;
  }
  
  .pickup-pages .h-title {
    display: flex;
    align-items: flex-start;
    font-weight: 600;
  }
  
  .pickup-pages  .print-layout .c-text{
    display: flex;
    align-items: flex-start;
    font-size: 10pt;
    font-weight: 400;
  }
  
  .pickup-pages .tx-form{
    font-size: 11px;
    line-height: 18px;
  }
  
  .pickup-pages .sec-item {
    width: var(---width-items);
    display: flex;
    flex-direction: column;
  }
  
  
  .pickup-pages .sec-parm {
    width: var(---width-param);
    display: flex;
    flex-direction: column;
  } 

  .pickup-pages .ant-typography {
    font-family: inherit; 
    box-sizing: border-box;
    vertical-align: top;
  }
  
  .pickup-pages .ant-card {
    background: #ffffff;
    border-radius: 0;
    box-shadow: none; 
    font-family: inherit !important;
  }

  .pickup-pages [class^="ant-table"], .pickup-pages [class*=" ant-table"] {
    font-family: inherit;
    font-size: 14px;
    box-sizing: border-box;
  }
  .pickup-pages .ant-table-wrapper .ant-table {
    font-family: inherit;
  }


  .pickup-pages .ant-table-wrapper .ant-table-thead >tr>th, 
  .pickup-pages .ant-table-wrapper .ant-table-thead >tr>td { 
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000;
    font-size: 12pt;
  }

  .pickup-pages .text-sm { 
    color: #242121; 
  }
 
  .pickup-pages .ant-table-wrapper .ant-table-tbody >tr >td {
    transition: none;
    border: 1px solid #000;
    white-space: break-spaces;
    font-size: 12pt;
    vertical-align: top;
  }
  .pickup-pages .ant-table-wrapper .ant-table-tbody tr:last-child td {
    border: 1px solid #000;
  }
 
  /* .pickup-pages .ant-table-wrapper .ant-table-tbody >tr:nth-child(even) >td {
    background-color: aliceblue;
  } */
  .pickup-pages .ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, 
  .pickup-pages .ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    inset-inline-end: 0;
    width: 0px;
    height: 1.6em;
    background-color: #ffffff; 
    content: "";
 }

.pickup-pages .ant-table-wrapper .ant-table-summary >tr >th, 
.pickup-pages .ant-table-wrapper .ant-table-summary >tr >td {
  border: 1px solid #000;
}

 
.pickup-pages .ant-table-wrapper .ant-table.ant-table-small .ant-table-title, 
.pickup-pages .ant-table-wrapper .ant-table.ant-table-small .ant-table-footer, 
.pickup-pages .ant-table-wrapper .ant-table.ant-table-small .ant-table-cell, 
.pickup-pages .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th, 
.pickup-pages .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>th, 
.pickup-pages .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td, 
.pickup-pages .ant-table-wrapper .ant-table.ant-table-small tfoot>tr>th, 
.pickup-pages .ant-table-wrapper .ant-table.ant-table-small tfoot>tr>td {
  padding: 2px 6px;
  font-family: inherit;
  background: #fff !important;
  transform: unset !important;
}



.pickup-pages .ant-card.ant-card-small >.ant-card-head {
    min-height: 30px;
    padding: 0 2px;
    padding-inline-start: 6px;
    font-size: 10.8pt;
    color: #000;
    background-color: #508ecc;
    border-radius: 0;
}

.pickup-pages .ant-card-bordered {
    border: 0px solid #f0f0f0;
    /* background-color: #bebebe; */
    border-radius: 0px;
}

.pickup-pages .ant-card .ant-card-head, 
.pickup-pages .ant-card .ant-card-body {
  padding: 0px;
}